import React from "react";
import "./index.less";

export default function Comp({ props, rectOptions }) {
  const { height, width, left, top } = rectOptions;
  const { imageUrl } = props;
  return (
    <div
      className="background-wrap"
      style={{
        width,
        height,
        transform: `translate(${left}px, ${top}px)`,
        // zIndex,
      }}
    >
      <img src={imageUrl} alt="" />
    </div>
  );
}

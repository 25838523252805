import React, { useState, useRef, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectCube } from "swiper";
import "swiper/swiper.min.css";
import "swiper/swiper-element.min.css";
import "swiper/swiper-element-bundle.min.css";
import "swiper/swiper-bundle.min.css";

const isVideo = (str) => {
  return str.indexOf(".mp4") > -1;
};

export default function Preview({ rectOptions, props, zIndex = 0 }) {
  const videoRef = useRef(null);
  const { width, height, left, top, subRectOptions } = rectOptions;
  const { banners, subProps, imageInterval, videoInterval } = props;
  const subProp = subProps[0];
  const subRect = subRectOptions[0];
  const subProp2 = subProps[1];
  const subRect2 = subRectOptions[1];
  const {
    defaultWidth,
    activeWidth,
    between,
    radius,
    defaultColor,
    activeColor,
  } = subProp;
  const subHeight = subRect.height;
  const subLeft = subRect.left;
  const subTop = subRect.top;
  const [activeIndex, setActiveIndex] = useState(0);
  const [delay, setDelay] = useState(
    isVideo(banners[0].mediaUrl) ? videoInterval : imageInterval
  );
  const [isMuted, setIsMuted] = useState(subProp2.muted);
  const dotRef = useRef(null);
  useEffect(() => {
    if (videoRef.current) {
      if (banners[activeIndex].mediaUrl.indexOf(".mp4") < 0) {
        videoRef.current.muted = true;
      } else {
        videoRef.current.muted = isMuted;
      }
    }

    if (subProp.type === 1) {
      const step = defaultWidth / banners.length;
      dotRef.current.style.transform = `translateX(${
        -defaultWidth + (activeIndex + 1) * step
      }px)`;
    }
  }, [activeIndex]);
  return (
    <>
      <div
        style={{
          width,
          height,
          left,
          top,
          zIndex,
          position: "absolute",
        }}
      >
        <Swiper
          modules={[Autoplay, EffectCube]}
          loop
          autoplay={{
            delay: delay * 1000,
            disableOnInteraction: false,
          }}
          effect="cube"
          cubeEffect={{
            shadow: false,
          }}
          onSlideChangeTransitionEnd={(e) => {}}
          onSlideChange={(e) => {
            if (isVideo(banners[e.realIndex].mediaUrl)) {
              setDelay(videoInterval);
            } else {
              setDelay(imageInterval);
            }
            setActiveIndex(e.realIndex);
          }}
        >
          {banners.map((banner, index) => {
            return (
              <SwiperSlide key={index}>
                <div
                  style={{
                    width,
                    height,
                  }}
                >
                  {banner.mediaUrl.indexOf(".mp4") > -1 ? (
                    <div
                      style={{
                        position: "relative",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <video
                        src={banner.mediaUrl}
                        muted={isMuted}
                        loop
                        autoPlay
                        style={{
                          width: "100%",
                          height: "100%",
                        }}
                        poster={banner.poster}
                        ref={videoRef}
                      ></video>
                      <img
                        src={banner.mask}
                        alt=""
                        style={{
                          width: "100%",
                          height: "100%",
                          position: "absolute",
                          top: 0,
                          left: 0,
                        }}
                      />
                    </div>
                  ) : (
                    <img
                      src={banner.mediaUrl}
                      alt=""
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  )}
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
      {subProp.type === 0 ? (
        <div
          style={{
            display: "flex",
            position: "absolute",
            left: subLeft,
            top: subTop,
            zIndex,
          }}
        >
          {banners.map((b, index) => {
            return (
              <div
                key={index + "dot"}
                style={{
                  width: activeIndex === index ? activeWidth : defaultWidth,
                  height: subHeight,
                  marginLeft: index === 0 ? 0 : between,
                  backgroundColor:
                    activeIndex === index ? activeColor : defaultColor,
                  borderRadius: radius,
                }}
              ></div>
            );
          })}
        </div>
      ) : (
        <div
          style={{
            position: "absolute",
            width: defaultWidth,
            height: subHeight,
            overflow: "hidden",
            borderRadius: radius,
            backgroundColor: defaultColor,
            left: subLeft,
            top: subTop,
            zIndex,
            // transform: `translate(${left}px, ${top}px)`,
          }}
        >
          <div
            ref={dotRef}
            style={{
              width: "100%",
              height: "100%",
              backgroundColor: activeColor,
              borderRadius: radius,
              transition: "100ms",
              transform: `translateX(-${defaultWidth}px)`,
            }}
          ></div>
        </div>
      )}

      {banners[activeIndex].mediaUrl.indexOf(".mp4") > -1 && (
        <img
          alt=""
          style={{
            width: subRect2.width,
            height: subRect2.height,
            left: subRect2.left,
            top: subRect2.top,
            position: "absolute",
            zIndex,
          }}
          onClick={() => {
            setIsMuted(!isMuted);
          }}
          src={isMuted ? subProp2.mutedImgUrl : subProp2.unmutedImgUrl}
        />
      )}
    </>
  );
}

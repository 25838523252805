import React, { useEffect, useState } from "react";
import { Space } from "antd";
import { DeleteFilled, PlusOutlined } from "@ant-design/icons";
import "@/plugins/desc.less";
import {
  Input,
  Select,
  ConfigTitle,
  ColorPicker,
  BaseButton,
} from "@/components";
import { InputNumber3x, HotArea, Slider3x, InputWithImg } from "@/HOC";
import _ from "lodash";

export default function Desc({
  currentPlugin,
  changeCurrentPluginRect,
  updateCurrentPluginProps,
  deleteInsertPlugin,
}) {
  const { props, rectOptions, extension } = currentPlugin;
  const { subProps, scrollList, between, scrollItemWidth, scrollItemHeight } =
    props;
  const { subRectOptions, width } = rectOptions;
  const [currentIndex, setCurrentIndex] = useState(0);

  // HACK 因为H5端本应该用rectOptions.width，而实际上用的是scrollWrapWidth，为了减少修改暂时调整画布侧去兼容h5端
  useEffect(() => {
    setTimeout(() => {
      updateCurrentPluginProps({
        scrollWrapWidth: rectOptions.width,
      });
    });
  }, [width]);

  return (
    <div className="desc-wrap">
      <ConfigTitle
        style={{
          padding: "20px 0 5px 20px",
        }}
        title="滑屏"
        detail="支持多张图片手势拖拽滑动"
      />
      <div className="desc-board">
        <Space direction="vertical" size={10}>
          <ConfigTitle
            title="滑屏素材设置"
            detail="每帧图片可单独编辑热区"
            style={{
              height: 37,
            }}
            titleSize={14}
            detailSize={12}
          />
          <Select
            chooseList={scrollList.map((v, i) => `第${i + 1}组`)}
            onClick={(idx) => {
              setCurrentIndex(idx);
            }}
            onDrop={(newIndexGrp) => {
              const newList = [];
              newIndexGrp.forEach((i) => {
                newList.push(scrollList[i]);
              });
              updateCurrentPluginProps({
                scrollList: newList,
              });
            }}
            currentIndex={currentIndex}
          />
          <InputWithImg
            labelContent="图片链接"
            defaultValue={scrollList[currentIndex].imageUrl}
            onChange={(e) => {
              const list = [...scrollList];
              list[currentIndex].imageUrl = e;
              updateCurrentPluginProps({
                scrollList: list,
              });
            }}
          />
          <HotArea
            editContentSize={{
              width: scrollItemWidth,
              height: scrollItemHeight,
            }}
            editContent={scrollList[currentIndex].imageUrl}
            defaultValue={scrollList[currentIndex].hotArea}
            contentFit="fill"
            onSave={(data) => {
              const list = [...props.scrollList];
              list[currentIndex].hotArea = data;
              updateCurrentPluginProps({
                scrollList: list,
              });
            }}
            showCopyBtn={currentIndex !== 0}
            onCopy={() => {
              const list = [...props.scrollList];
              list[currentIndex].hotArea = _.cloneDeep(list[0].hotArea, {});
              updateCurrentPluginProps({
                scrollList: list,
              });
            }}
          />
          <Space>
            <BaseButton
              style={{
                width: 155,
              }}
              type="normal"
              icon={<DeleteFilled />}
              content="删除本组"
              onClick={() => {
                const list = [...scrollList];
                list.splice(currentIndex, 1);
                if (list.length === 0) {
                  deleteInsertPlugin(currentPlugin.insertId);
                } else {
                  setCurrentIndex(0);
                  updateCurrentPluginProps({
                    scrollList: list,
                  });
                }
              }}
            />
            <BaseButton
              style={{
                width: 155,
              }}
              type="normal"
              icon={<PlusOutlined />}
              content="添加帧数"
              onClick={() => {
                const list = [...scrollList];
                list.push(Object.assign({}, extension.struct));
                updateCurrentPluginProps({
                  scrollList: list,
                });
              }}
            />
          </Space>
        </Space>
      </div>
      <div className="desc-board">
        <Space direction="vertical" size={10}>
          <ConfigTitle
            title="滑屏图设置"
            style={{
              height: 21,
            }}
            titleSize={14}
            detailSize={12}
          />
          <Space size={10}>
            <InputNumber3x
              labelContent="宽"
              size="middle"
              style={{
                color: "#696969",
              }}
              onChange={(val) => {
                updateCurrentPluginProps({
                  scrollItemWidth: val,
                });
              }}
              defaultValue={scrollItemWidth}
            />
            <InputNumber3x
              labelContent="高"
              size="middle"
              style={{
                color: "#696969",
              }}
              onChange={(val) => {
                updateCurrentPluginProps({
                  scrollItemHeight: val,
                });
              }}
              defaultValue={scrollItemHeight}
            />
          </Space>
          <Space size={10}>
            <InputNumber3x
              labelContent="上边距"
              size="middle"
              style={{
                color: "#696969",
              }}
              onChange={(val) => {
                changeCurrentPluginRect({
                  top: val,
                });
              }}
              defaultValue={rectOptions.top}
            />
            <InputNumber3x
              labelContent="左边距"
              size="middle"
              style={{
                color: "#696969",
              }}
              onChange={(val) => {
                changeCurrentPluginRect({
                  left: val,
                });
              }}
              defaultValue={rectOptions.left}
            />
          </Space>
          <InputNumber3x
            labelContent="间距"
            size="middle"
            style={{
              color: "#696969",
            }}
            onChange={(val) => {
              updateCurrentPluginProps({
                between: val,
              });
            }}
            defaultValue={between}
          />
          <Slider3x
            title="滑动宽度"
            min={extension.scrollWidthDuration[0]}
            max={extension.scrollWidthDuration[1]}
            defaultValue={rectOptions.width}
            suffix="px"
            onChange={(v) => {
              changeCurrentPluginRect({
                width: v,
              });
            }}
          />
        </Space>
      </div>
      <div className="desc-board">
        <Space direction="vertical" size={10}>
          <ConfigTitle
            title="进度点设置"
            style={{
              height: 21,
            }}
            titleSize={14}
            detailSize={0}
          />
          <Space size={10}>
            <InputNumber3x
              labelContent="宽"
              size="middle"
              style={{
                color: "#696969",
              }}
              onChange={(val) => {
                subRectOptions[0].width = val;
                changeCurrentPluginRect(subRectOptions, 1);
              }}
              defaultValue={subRectOptions[0].width}
            />
            <InputNumber3x
              labelContent="高"
              size="middle"
              style={{
                color: "#696969",
              }}
              onChange={(val) => {
                subRectOptions[0].height = val;
                changeCurrentPluginRect(subRectOptions, 1);
              }}
              defaultValue={subRectOptions[0].height}
            />
          </Space>

          <Space size={10}>
            <InputNumber3x
              labelContent="上边距"
              size="middle"
              style={{
                color: "#696969",
              }}
              onChange={(val) => {
                subRectOptions[0].top = val;
                changeCurrentPluginRect(subRectOptions, 1);
              }}
              defaultValue={subRectOptions[0].top}
            />
            <InputNumber3x
              labelContent="左边距"
              size="middle"
              style={{
                color: "#696969",
              }}
              onChange={(val) => {
                subRectOptions[0].left = val;
                changeCurrentPluginRect(subRectOptions, 1);
              }}
              defaultValue={subRectOptions[0].left}
            />
          </Space>
          <InputNumber3x
            labelContent="圆角"
            size="middle"
            style={{
              color: "#696969",
            }}
            onChange={(val) => {
              updateCurrentPluginProps(
                {
                  radius: val,
                },
                1
              );
            }}
            defaultValue={subProps[0].radius}
          />
          <ColorPicker
            title="默认颜色"
            onChange={(v) => {
              updateCurrentPluginProps(
                {
                  defaultColor: v,
                },
                1
              );
            }}
            defaultValue={subProps[0].defaultColor}
          />
          <ColorPicker
            title="激活颜色"
            onChange={(v) => {
              updateCurrentPluginProps(
                {
                  activeColor: v,
                },
                1
              );
            }}
            defaultValue={subProps[0].activeColor}
          />
        </Space>
      </div>
    </div>
  );
}

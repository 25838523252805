import {
  useState,
  createContext,
  useCallback,
  useContext,
  useRef,
  useEffect,
} from "react";
import { BaseConfigContext } from "./BaseConfig";
import _ from "lodash";

export const InsertContext = createContext({});

// 插入到画布中的插件
const InsertProvider = ({ children }) => {
  const { maxSize } = useContext(BaseConfigContext);
  const [insertList, setInsertList] = useState([]);
  const [currentPlugin, setCurrentPlugin] = useState({});
  const _currentInsertPlugin = useRef({});
  const _insertList = useRef([]);
  const isInputInsert = useRef(false);
  useEffect(() => {
    _insertList.current = insertList.filter(
      (item, index, self) =>
        index === self.findIndex((t) => t.name === item.name)
    );
  }, [insertList]);

  useEffect(() => {
    _currentInsertPlugin.current = currentPlugin;
  }, [currentPlugin]);

  const _handleSetCurrentPlugin = useCallback(() => {
    setCurrentPlugin(_currentInsertPlugin.current);
  }, []);

  // 初始化
  const initInsertProvider = () => {
    setCurrentPlugin({});
    setInsertList([]);
  };

  // 修改当前插件的宽高上边距左边距
  const handleChangeCurrentPluginRect = useCallback(
    ({ width, height, left, top }, target = 0) => {
      // target为0修改自身属性 大于0修改子插件属性
      let rect =
        target === 0
          ? _currentInsertPlugin.current.rectOptions
          : _currentInsertPlugin.current.rectOptions.subRectOptions[target - 1];
      // rect.width = Math.min(width ? width : rect.width, maxSize.width)
      // rect.height = Math.min(height ? height: rect.height, maxSize.height)
      rect.width = width !== undefined ? width : rect.width;
      rect.height = height !== undefined ? height : rect.height;
      rect.left = left !== undefined ? left : rect.left;
      rect.top = top !== undefined ? top : rect.top;
      setCurrentPlugin(_.cloneDeep(_currentInsertPlugin.current));
      updateCurPluginFromList();
    },
    [currentPlugin]
  );

  // 修改当前插件的Props
  const handleUpdatePluginProps = useCallback(
    (newProps = {}, target = 0) => {
      let props =
        target === 0
          ? _currentInsertPlugin.current.props
          : _currentInsertPlugin.current.props.subProps[target - 1];
      props = Object.assign(props ?? {}, newProps ?? {});
      setCurrentPlugin(_.cloneDeep(_currentInsertPlugin.current));
      updateCurPluginFromList();
    },
    [currentPlugin]
  );

  // 添加子插件
  const handleAddSubPlugins = useCallback(
    ({
      comp = null,
      props = _currentInsertPlugin.current.props.subProps[0] || {},
      rectOptions = _currentInsertPlugin.current.rectOptions
        .subRectOptions[0] || {},
      drawOptions = _currentInsertPlugin.current.drawOptions.subOptions[0] ||
        {},
    }) => {
      _currentInsertPlugin.current.subPlugins.push(comp);
      _currentInsertPlugin.current.props.subProps.push(props);
      _currentInsertPlugin.current.rectOptions.subRectOptions.push(rectOptions);
      _currentInsertPlugin.current.drawOptions.subOptions.push(drawOptions);
      setCurrentPlugin(_.cloneDeep(_currentInsertPlugin.current));
      updateCurPluginFromList();
    },
    [currentPlugin]
  );

  const handleDeleteSubPlugins = useCallback(
    (idx) => {
      _currentInsertPlugin.current.subPlugins.splice(idx, 1);
      _currentInsertPlugin.current.props.subProps.splice(idx, 1);
      _currentInsertPlugin.current.rectOptions.subRectOptions.splice(idx, 1);
      _currentInsertPlugin.current.drawOptions.subOptions.splice(idx, 1);
      setCurrentPlugin(_.cloneDeep(_currentInsertPlugin.current));
      updateCurPluginFromList();
    },
    [currentPlugin]
  );

  // 移除子插件
  const updateCurPluginFromList = useCallback(() => {
    const newList = [..._insertList.current];
    newList.forEach((item, index) => {
      if (item.insertId === _currentInsertPlugin.current.insertId) {
        newList[index] = _currentInsertPlugin.current;
        setInsertList(newList);
      }
    });
  }, [insertList]);

  // 向画布中插入插件
  const insert = useCallback(
    (plugin) => {
      if (Array.isArray(plugin)) {
        let newList = [..._insertList.current];
        // 如果是导入的组件就清空画布内容
        if (isInputInsert.value) {
          newList = [];
        }
        plugin.forEach((newPlugin, index) => {
          newPlugin.insertId = index + "" + Date.now();
          newList.push(newPlugin);
        });
        setInsertList(newList);
        _handleSetCurrentPlugin();
      } else {
        const newPlugin = Object.assign({}, plugin);
        let newList = [..._insertList.current];
        if (isInputInsert.value) {
          newList = [];
        }
        newPlugin.insertId = Date.now() + "";
        newList.push(newPlugin);
        setInsertList(newList);
        _handleSetCurrentPlugin();
      }
    },
    [insertList]
  );

  // 调整插件层级顺序
  const exchangePluginOrder = useCallback(
    (o, t) => {
      const newList = [..._insertList.current];
      const m = newList[o];
      newList[o] = newList[t];
      newList[t] = m;
      setInsertList(newList);
    },
    [insertList]
  );

  // 删除已经插入的插件
  const deleteInsertPlugin = useCallback(
    (insertId) => {
      const newList = [..._insertList.current];
      for (let i = 0; i < newList.length; i++) {
        if (newList[i].insertId === insertId) {
          newList.splice(i, 1);
          break;
        }
      }
      if (insertId === _currentInsertPlugin.current.insertId) {
        setCurrentPlugin({});
      }
      setInsertList(newList);
    },
    [insertList, currentPlugin]
  );

  return (
    <InsertContext.Provider
      value={{
        isInputInsert,
        insertList,
        insert,
        currentPlugin,
        init: () => {
          initInsertProvider();
        },
        setCurrentPlugin: (plugin) => {
          setCurrentPlugin(plugin);
        },
        changeCurrentPluginRect: handleChangeCurrentPluginRect,
        updateCurrentPluginProps: handleUpdatePluginProps,
        addSubPlugins: handleAddSubPlugins,
        deleteSubPlugins: handleDeleteSubPlugins,
        exchangePluginOrder,
        deleteInsertPlugin,
        findTargetByType: (type) => {
          let result = null;
          insertList.forEach((item, index) => {
            if (item.type === type) {
              result = item;
            }
          });
          return result;
        },
      }}
    >
      {children}
    </InsertContext.Provider>
  );
};

export default InsertProvider;
